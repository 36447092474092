import { Form } from 'react-bootstrap'
import { SignalEntry } from '../../../api/CloudApi/types'

interface SignalItemProps {
    thisSignalEntry: SignalEntry
    selectedSignals: Array<SignalEntry>
    addSelectedSignalFunction: (signal: SignalEntry) => void
    removeSelectedSignalFunction: (signal: SignalEntry) => void
    isDisabled: boolean
}

export default function SignalItem(props: SignalItemProps) {
    const selectSignalOnChange = (event: any, targetedSignal: SignalEntry) => {
        if (event.target.checked) {
            props.addSelectedSignalFunction(targetedSignal)
        } else {
            props.removeSelectedSignalFunction(targetedSignal)
        }
    }

    const signalItem = (signalEntry: SignalEntry) => {
        const isChecked = props.selectedSignals.some((it) => it.name === signalEntry.name && it.namespace === signalEntry.namespace)

        return (
            <div
                key={`${signalEntry.namespace}-${signalEntry.frameName}-${signalEntry.name}-signal-item`}
                className={`rounded mt-1 ${
                    props.isDisabled ? 'remotive-neutral-40-background' : 'remotive-primary-10-background'
                }`}
            >
                <fieldset disabled={props.isDisabled}>
                    <div className="row text-break lexend-regular align-middle p-0 m-0">
                        <div className="col-6">
                            <div style={{ paddingTop: 2, paddingBottom: 2 }} className="d-flex align-items-center">
                                <Form.Check
                                    id={`${signalEntry.namespace}-${signalEntry.frameName}-${signalEntry.name}-signal-item`}
                                    type={'checkbox'}
                                    value={JSON.stringify(signalEntry)}
                                    checked={isChecked}
                                    onChange={(e) => selectSignalOnChange(e, signalEntry)}
                                />
                                <label
                                    htmlFor={`${signalEntry.namespace}-${signalEntry.frameName}-${signalEntry.name}-signal-item`}
                                    className="form-check-label remotive-font-sm ms-2"
                                >
                                    {signalEntry.name}
                                </label>
                            </div>
                        </div>
                        <div className="col-2 remotive-font-sm d-flex align-items-center">
                            <p className="remotive-primary-40-color ps-1 m-0">{`${signalEntry.unit ?? 'unitless'}`}</p>
                        </div>
                        <div className="col-2 remotive-font-sm d-flex align-items-center">
                            <p className="remotive-primary-40-color ps-1 m-0">{`${signalEntry.length} bits`}</p>
                        </div>
                        <div className="col-2 remotive-font-sm d-flex align-items-center">
                            <p className="remotive-primary-40-color ps-1 m-0">{`${signalEntry.min} - ${signalEntry.max}`}</p>
                        </div>
                        <div className="col-12">
                            {signalEntry.comments !== null && (
                                <p className="remotive-font-sm remotive-neutral-70-color mt-0 pb-0 mb-1 py-0 ms-4 text-wrap">
                                    {signalEntry.comments}
                                </p>
                            )}
                        </div>
                    </div>
                </fieldset>
            </div>
        )
    }

    return signalItem(props.thisSignalEntry)
}
