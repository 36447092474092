import { toast } from 'react-toastify'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import {
    AuthenticatedUser,
    Organisation,
    Project,
    RecordingFile,
    RecordingSession,
    UserBillableUnitInfo,
} from '../api/CloudApi/types'
import { DeleteIcon, DownloadIcon, EditIcon, WarningIcon } from '../assets/Icons'
import { Permission, hasPermission } from '../utils/permission'
import CloudApi from '../api/CloudApi'
import { ComponentState } from '../types/ComponentState'
import { ConfirmDialogProperties } from '../types/ConfirmDialogProperties'
import {
    AnalyticsContext,
    createAnalyticsTrackingKey,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from '../utils/ProductAnalytics'

const AnalyticsActions = {
    DOWNLOAD_RECORDING_FILES: createAnalyticsTrackingKey(AnalyticsContext.FILES_TAB, 'DownloadRecordingFiles'),
    EDIT_RECORDING_FILES: createAnalyticsTrackingKey(AnalyticsContext.FILES_TAB, 'EditRecordingFiles'),
    DELETE_RECORDING_FILES: createAnalyticsTrackingKey(AnalyticsContext.FILES_TAB, 'DeleteRecordingFiles'),
}

interface RecordingFileListItemProps {
    currentUser: AuthenticatedUser | undefined
    recordingFile: RecordingFile
    parentRecordingSession: RecordingSession
    project: Project
    billableUnit: UserBillableUnitInfo
    refreshRecordingSession: Function
    editRecordingFunction: (recordingFile: RecordingFile) => void
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

export default function RecordingFileListItem(props: RecordingFileListItemProps) {
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.DONE)

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.currentUser,
        billableUnit: props.billableUnit,
    } as ProductAnalyticsProps)

    const deleteRecordingFile = async (recordingFile: RecordingFile) => {
        try {
            setComponentState(ComponentState.DELETING)
            await CloudApi.deleteRecordingFile(props.project.uid, props.parentRecordingSession.sessionId, recordingFile)
            await props.refreshRecordingSession() // Fetch recording session again with the updated details
            toast.success(`Successfully deleted recording file ${recordingFile.displayName}`)
        } catch (err) {
            toast.error(`Failed to delete recording ${recordingFile.displayName}`)
            setComponentState(ComponentState.DONE)
        }
    }

    const downloadRecordingFile = async (recordingFileName: string) => {
        try {
            setComponentState(ComponentState.LOADING)
            const { downloadUrl } = (
                await CloudApi.getRecordingFile(
                    props.project.uid,
                    props.parentRecordingSession.sessionId,
                    recordingFileName
                )
            ).data
            if (downloadUrl) {
                window.open(downloadUrl)
            }
            setComponentState(ComponentState.DONE)
        } catch (err) {
            toast.error(`Failed to download ${recordingFileName}`)
            setComponentState(ComponentState.DONE)
        }
    }

    const recordingFileListItem = (
        recordingFile: RecordingFile,
        project: Project,
        billableUnit: UserBillableUnitInfo
    ) => {
        const isMissingDatabase = recordingFile.metadata?.database === null
        return (
            <div key={recordingFile.fileName}>
                <div className={`d-flex rounded remotive-primary-10-background m-1`}>
                    <div className="col-4 col-md-5 px-2 pt-1 pb-1">
                        <div className="border-end h-100">
                            <p className="m-0 remotive-font-md text-truncate">{recordingFile.displayName}</p>
                            {isMissingDatabase ? (
                                <>
                                    <p className="m-0 remotive-font-sm text-secondary text-truncate">
                                        Please set a signal database or delete this file
                                    </p>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className="col-3 px-2 pt-1 pb-1 flex-truncate">
                        <div className="border-end h-100 flex-truncate">
                            <p className="m-0 remotive-font-md text-truncate">Database</p>
                            {isMissingDatabase ? (
                                <>
                                    <div className="text-start">
                                        <button
                                            onClick={() => props.editRecordingFunction(recordingFile)}
                                            disabled={
                                                !hasPermission(
                                                    Permission.PROJECT_EDITOR_RECORDING,
                                                    billableUnit,
                                                    project
                                                )
                                            }
                                            className="btn remotive-btn-sm remotive-btn-primary m-0 px-2 mb-1 me-2 d-flex align-items-center"
                                        >
                                            <WarningIcon sx={{ fontSize: 10 }} className="text-warning me-1" />
                                            <p className="d-none d-sm-block remotive-font-xs m-0">Set database</p>
                                            <p className="d-block d-sm-none remotive-font-xs m-0">Set</p>
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <p className="m-0 remotive-font-sm text-secondary text-truncate">
                                    {recordingFile.metadata?.database}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="col-2 px-2 pt-1 pb-1">
                        <div className="h-100 border-end">
                            <p className="m-0 remotive-font-md text-truncate">Namespace</p>
                            <p className="m-0 remotive-font-sm text-secondary text-truncate">
                                {recordingFile.metadata?.namespace}
                            </p>
                        </div>
                    </div>
                    <div className="col-3 col-md-2 d-flex justify-content-center align-items-center remotive-primary-60-color text-truncate">
                        <button
                            className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0 me-1'}
                            disabled={!hasPermission(Permission.PROJECT_EDITOR_RECORDING, billableUnit, project)}
                            onClick={() => {
                                productAnalyticsClient.track(AnalyticsActions.EDIT_RECORDING_FILES)
                                props.editRecordingFunction(recordingFile)
                            }}
                        >
                            <EditIcon sx={{ fontSize: 19 }} />
                        </button>
                        <button
                            className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0 me-1'}
                            disabled={!hasPermission(Permission.PROJECT_VIEWER_RECORDING, billableUnit, project)}
                            onClick={() => {
                                productAnalyticsClient.track(AnalyticsActions.DOWNLOAD_RECORDING_FILES)
                                downloadRecordingFile(recordingFile.fileName)
                            }}
                        >
                            <DownloadIcon sx={{ fontSize: 20 }} />
                        </button>
                        <button
                            className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                            disabled={!hasPermission(Permission.PROJECT_EDITOR_RECORDING, billableUnit, project)}
                            onClick={() =>
                                props.openConfirmationDialog({
                                    dialogTitle: 'Are you sure?',
                                    bodyText: (
                                        <>
                                            Are you sure you want to delete the recording file{' '}
                                            <b>{recordingFile.fileName}</b>?
                                        </>
                                    ),
                                    bodySubtitle: 'You can not undo this action.',
                                    confirmButtonText: 'Yes, delete it',
                                    cancelButtonText: 'No, cancel',
                                    handleCancelFunction: () => console.log,
                                    handleConfirmFunction: () => {
                                        productAnalyticsClient.track(AnalyticsActions.DELETE_RECORDING_FILES)
                                        deleteRecordingFile(recordingFile)
                                    },
                                } as ConfirmDialogProperties)
                            }
                        >
                            {componentState === ComponentState.DELETING ? (
                                <Spinner size="sm" className="me-1" />
                            ) : (
                                <DeleteIcon sx={{ fontSize: 20 }} />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return recordingFileListItem(props.recordingFile, props.project, props.billableUnit)
}
