import { Spinner } from 'react-bootstrap'

interface LoadingContainerProps {
    loadingText?: string
    infoText?: string
    variant?: 'white' | undefined
    spinnerSize: 'sm' | 'lg'
}

function LoadingContainer(props: LoadingContainerProps) {
    return (
        <>
            <div className="text-center h-75 mt-5 mb-5">
                <p className={`fs-5 m-1 ${props.variant === 'white' ? 'text-white' : ''}`}>
                    {props.loadingText || 'Loading...'}
                </p>
                {props.infoText && <p>{props.infoText}</p>}
                <Spinner
                    className={`remotive-primary-50-color ${props.variant === 'white' ? 'text-white' : ''}`}
                    size={props.spinnerSize === 'lg' ? undefined : 'sm'}
                />
            </div>
        </>
    )
}

export default LoadingContainer
