import { useRef, useState, useEffect } from 'react'
import ViewFileModal from '../../components/ViewFileModal'
import {
    AuthenticatedUser,
    BrokerConfigurationFolder,
    MediaFile,
    ProcessingRecordingFile,
    ProcessingRecordingFileStatus,
    Project,
    RecordingFile,
    RecordingSession,
    SignalDatabase,
    SourceRecordingFile,
    UserBillableUnitInfo,
} from '../../api/CloudApi/types'
import Dropzone, { Accept, FileWithPath } from 'react-dropzone'
import CloudApi from '../../api/CloudApi'
import { toast } from 'react-toastify'
import { PageState } from '../../types/PageState'
import {
    CloudUploadIcon,
    DeleteIcon,
    DownloadIcon,
    EditIcon,
    PlayIcon,
    SuccessIcon,
    WarningIcon,
} from '../../assets/Icons'
import { hasPermission, Permission } from '../../utils/permission'
import { all, AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import { Card, ProgressBar, Spinner } from 'react-bootstrap'
import CliHintContainer, { CliHint } from '../../components/CliHintContainer'
import EditRecordingConfigurationModal from './EditRecordingConfigurationModal'
import ProcessingRecordingsAccordion from '../../components/ProcessingRecordingsAccordion'
import { ComponentState } from '../../types/ComponentState'
import FileUploadingSection from '../../components/FileUploadContainer'
import RecordingFileListItem from '../../components/RecordingFileListItem'
import { formattedToastMessage } from '../../utils/toast'
import RecordingDropzone from '../../components/RecordingDropzone'
import randomString from '../../utils/randomString'
import { ConfirmDialogProperties } from '../../types/ConfirmDialogProperties'
import {
    AnalyticsContext,
    AnalyticsProperties,
    createAnalyticsTrackingKey,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from '../../utils/ProductAnalytics'

const ACCEPTED_MEDIA_FILE_TYPES = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'image/webp': ['.webp'],
    'application/zip': ['.zip'],
    'application/x-ipynb+json': ['.ipynb'],
    'video/quicktime': ['.mov'],
    'video/mp4': ['.mp4'],
}
const PLAYABLE_MIME_TYPES = ['video/quicktime', 'video/mp4']
const DOWNLOADABLE_MIME_TYPES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
    'application/zip',
    'application/x-ipynb+json',
]

const AnalyticsActions = {
    UPLOAD_CONFIGURATION: createAnalyticsTrackingKey(AnalyticsContext.FILES_TAB, 'UploadConfiguration'),
    DOWNLOAD_CONFIGURATION: createAnalyticsTrackingKey(AnalyticsContext.FILES_TAB, 'DownloadConfiguration'),
    DELETE_CONFIGURATION: createAnalyticsTrackingKey(AnalyticsContext.FILES_TAB, 'DeleteConfiguration'),
    UPLOAD_MEDIA_FILE: createAnalyticsTrackingKey(AnalyticsContext.FILES_TAB, 'UploadMediaFiles'),
    DOWNLOAD_MEDIA_FILE: createAnalyticsTrackingKey(AnalyticsContext.FILES_TAB, 'DownloadMediaFiles'),
    DELETE_MEDIA_FILE: createAnalyticsTrackingKey(AnalyticsContext.FILES_TAB, 'DeleteMediaFiles'),
}

interface FilesTabProps {
    recordingRequiresConfiguration: boolean
    recordingConfigurationReason: string | undefined
    currentRecording: RecordingSession | undefined
    currentProject: Project | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentUser: AuthenticatedUser | undefined
    setPageState: (pageState: PageState) => void
    refreshRecordingSession: () => void
    setLoadingPercent: (percent: number) => void
    availableSignalDatabases: Array<SignalDatabase>
    isRecordingValid: boolean | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

export function FilesTab(props: FilesTabProps) {
    const [showEditRecordingConfigurationModal, setShowEditRecordingConfigurationModal] = useState<boolean>(true)
    const [showViewFileModal, setShowViewFileModal] = useState<boolean>(false)
    const [brokerConfigFilesPreparedForUpload, setBrokerConfigFilesPreparedForUpload] = useState<Array<FileWithPath>>()
    const fileToView = useRef<MediaFile>()
    const [recordingFileToEdit, setRecordingFileToEdit] = useState<RecordingFile>()
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.DEFAULT)
    const [uploadPercent, setUploadPercent] = useState<number>(0)
    const [processingAccordionKey, setProcessingAccordionKey] = useState<string>('processingRecording')

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.currentUser,
        billableUnit: props.currentBillableUnit,
    } as ProductAnalyticsProps)

    const saveBrokerConfigFiles = async () => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        if (projectUid && recordingSessionId !== undefined) {
            try {
                setComponentState(ComponentState.UPLOADING)
                await CloudApi.uploadBrokerConfigToRecordingSession(
                    projectUid,
                    recordingSessionId!,
                    'asdf',
                    brokerConfigFilesPreparedForUpload!
                )
                await props.refreshRecordingSession()
                setComponentState(ComponentState.DONE)
                setBrokerConfigFilesPreparedForUpload(undefined)
            } catch (err: any) {
                toast.error('Failed to upload configuration directory')
                props.setPageState(PageState.DONE)
            }
        }
    }

    const downloadBrokerConfigAsZip = async (folder: BrokerConfigurationFolder) => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        if (projectUid && recordingSessionId !== undefined) {
            const response = await CloudApi.downloadBrokerConfiguration(projectUid, recordingSessionId!, folder)
            var link = document.createElement('a')
            link.download = `${folder.name}.zip`
            var blob = new Blob([response.data], { type: 'application/zip' })
            link.href = window.URL.createObjectURL(blob)
            link.click()
        }
    }

    const deleteBrokerConfigFiles = async (folder: BrokerConfigurationFolder, skipToast: boolean = false) => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        if (projectUid && recordingSessionId !== undefined) {
            try {
                props.setPageState(PageState.DELETING)
                await CloudApi.deleteBrokerConfigFolder(projectUid, recordingSessionId!, folder)
                await props.refreshRecordingSession()
                if (!skipToast) {
                    toast.success(
                        formattedToastMessage('Sucess', `Successfully deleted signal transformation ${folder.name}`)
                    )
                }
                props.setPageState(PageState.DONE)
            } catch (err: any) {
                toast.error(formattedToastMessage('Error', 'Failed to delete signal transformation'))
                props.setPageState(PageState.DONE)
            }
        }
    }

    const deleteMediaFile = async (mediaFile: MediaFile) => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        if (projectUid && recordingSessionId !== undefined) {
            try {
                props.setPageState(PageState.DELETING)
                await CloudApi.deleteMediaFile(projectUid, recordingSessionId, mediaFile)
                toast.success(`Successfully deleted media file ${mediaFile.displayName}`)
                props.refreshRecordingSession() // Fetch recording session again with the updated details
            } catch (err) {
                toast.error(`Failed to delete ${mediaFile.displayName}`)
                props.setPageState(PageState.DONE)
            }
        }
    }

    const deleteSourceRecordingFile = async (mediaFile: SourceRecordingFile) => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        if (projectUid && recordingSessionId !== undefined) {
            try {
                props.setPageState(PageState.DELETING)
                await CloudApi.deleteSourceRecordingFile(projectUid, recordingSessionId, mediaFile)
                toast.success(`Successfully deleted source recording file ${mediaFile.displayName}`)
                props.refreshRecordingSession() // Fetch recording session again with the updated details
            } catch (err) {
                toast.error(`Failed to delete ${mediaFile.displayName}`)
                props.setPageState(PageState.DONE)
            }
        }
    }

    const uploadMediaFile = async (file: File) => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        if (projectUid && recordingSessionId !== undefined) {
            const filesWithSameName = props.currentRecording?.mediaFiles.filter(
                (mediaFile) => mediaFile.fileName === file.name
            )
            if (filesWithSameName && filesWithSameName.length > 0) {
                toast.warning(`File ${file.name} already exists`)
                return
            }

            const c = {
                onUploadProgress: function (progressEvent: AxiosProgressEvent) {
                    setUploadPercent(Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1)))
                },
            } as AxiosRequestConfig<EventTarget>

            try {
                props.setPageState(PageState.UPLOADING)
                await CloudApi.uploadRecordingSessionMediaFile(projectUid, recordingSessionId, file, c)
                toast.success(`Successfully uploaded media file ${file.name}`)
                await props.refreshRecordingSession()
                setUploadPercent(0)
            } catch (err) {
                toast.error('Failed to upload media file')
                props.setPageState(PageState.DONE)
            }
        }
    }

    const downloadMediaFile = async (mediaFile: MediaFile) => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        if (projectUid && recordingSessionId !== undefined) {
            try {
                props.setPageState(PageState.LOADING)
                const { downloadUrl } = (await CloudApi.getMediaFile(projectUid, recordingSessionId, mediaFile)).data
                window.open(downloadUrl, '_blank')
                props.setPageState(PageState.DONE)
            } catch (err) {
                toast.error(`Failed to download ${mediaFile.displayName}`)
                props.setPageState(PageState.DONE)
            }
        }
    }

    const downloadSourceRecordingFile = async (sourceRecordingFile: SourceRecordingFile) => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        if (projectUid && recordingSessionId !== undefined) {
            try {
                props.setPageState(PageState.LOADING)
                const { downloadUrl } = (
                    await CloudApi.getSourceRecordingFile(projectUid, recordingSessionId, sourceRecordingFile)
                ).data
                window.open(downloadUrl, '_blank')
                props.setPageState(PageState.DONE)
            } catch (err) {
                toast.error(`Failed to download ${sourceRecordingFile.displayName}`)
                props.setPageState(PageState.DONE)
            }
        }
    }

    const openMediaFile = async (mediaFile: MediaFile) => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        if (projectUid && recordingSessionId !== undefined) {
            try {
                props.setPageState(PageState.LOADING)
                const { downloadUrl } = (await CloudApi.getMediaFile(projectUid, recordingSessionId, mediaFile)).data
                window.open(downloadUrl, '_blank')
                props.setPageState(PageState.DONE)
            } catch (err) {
                toast.error(`Failed to download ${mediaFile.displayName}`)
                props.setPageState(PageState.DONE)
            }
        }
    }

    const playMediaFile = async (mediaFile: MediaFile) => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        if (projectUid && recordingSessionId !== undefined) {
            try {
                props.setPageState(PageState.LOADING_VIDEO)
                const file = (await CloudApi.getMediaFile(projectUid, recordingSessionId, mediaFile)).data
                fileToView.current = file
                setShowViewFileModal(true)
            } catch (err) {
                toast.error(`Failed to download and play ${mediaFile.displayName}`)
            }
        }
    }

    const mediaFileList = () => {
        return props.currentRecording?.mediaFiles.map((mediaFile) => {
            return (
                <div key={mediaFile.fileName}>
                    <div className="d-flex rounded remotive-primary-10-background m-1">
                        <div className="col-7 px-2 pt-1 pb-1">
                            <div className="border-end h-100">
                                <p className="m-0 remotive-font-md text-truncate">{mediaFile.displayName}</p>
                            </div>
                        </div>
                        <div className="col-3 col-md-2 px-2 pt-1 pb-1">
                            <div className="border-end h-100">
                                <p className="m-0 remotive-font-md text-truncate">File size</p>
                                <p className="m-0 text-secondary remotive-font-sm text-truncate">{`${
                                    Math.round((mediaFile.sizeInBytes / 1000 + Number.EPSILON) * 10) / 10
                                } KB`}</p>
                            </div>
                        </div>
                        <div className="col-3 col-md-2 d-none d-md-inline-block px-2 pt-1 pb-1">
                            <div className="border-end h-100">
                                <p className="m-0 remotive-font-md">Media type</p>
                                <p className="m-0 text-secondary remotive-font-sm">{mediaFile.mimeType}</p>
                            </div>
                        </div>
                        <div className="col-2 col-md-1 d-flex justify-content-center align-items-center p-1 remotive-primary-60-color">
                            {PLAYABLE_MIME_TYPES.includes(mediaFile.mimeType) ? (
                                <button
                                    className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                                    disabled={
                                        !hasPermission(
                                            Permission.PROJECT_VIEWER_RECORDING,
                                            props.currentBillableUnit,
                                            props.currentProject
                                        )
                                    }
                                    onClick={() => playMediaFile(mediaFile)}
                                >
                                    <PlayIcon sx={{ fontSize: 20 }} />
                                </button>
                            ) : (
                                <></>
                            )}
                            {DOWNLOADABLE_MIME_TYPES.includes(mediaFile.mimeType) ? (
                                <button
                                    className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                                    disabled={
                                        !hasPermission(
                                            Permission.PROJECT_VIEWER_RECORDING,
                                            props.currentBillableUnit,
                                            props.currentProject
                                        )
                                    }
                                    onClick={() => {
                                        if (mediaFile.preferDownload) {
                                            productAnalyticsClient.track(AnalyticsActions.DOWNLOAD_MEDIA_FILE)
                                            downloadMediaFile(mediaFile)
                                        } else {
                                            productAnalyticsClient.track(AnalyticsActions.DOWNLOAD_MEDIA_FILE)
                                            openMediaFile(mediaFile)
                                        }
                                    }}
                                >
                                    <DownloadIcon sx={{ fontSize: 20 }} />
                                </button>
                            ) : (
                                <></>
                            )}
                            <button
                                className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_EDITOR_RECORDING,
                                        props.currentBillableUnit,
                                        props.currentProject
                                    )
                                }
                                onClick={() =>
                                    props.openConfirmationDialog({
                                        dialogTitle: 'Are you sure?',
                                        bodyText: (
                                            <>
                                                Are you sure you want to delete the media file{' '}
                                                <b>{mediaFile.displayName}?</b>
                                            </>
                                        ),
                                        bodySubtitle: 'You can not undo this action.',
                                        confirmButtonText: 'Yes, delete it',
                                        cancelButtonText: 'No, cancel',
                                        handleCancelFunction: () => console.log,
                                        handleConfirmFunction: () => {
                                            productAnalyticsClient.track(AnalyticsActions.DELETE_MEDIA_FILE)
                                            deleteMediaFile(mediaFile)
                                        },
                                    } as ConfirmDialogProperties)
                                }
                            >
                                <DeleteIcon sx={{ fontSize: 20 }} />
                            </button>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const sourceRecordingFileList = () => {
        return props.currentRecording?.sourceRecordingFiles.map((sourceRecordingFile) => {
            return (
                <div key={sourceRecordingFile.fileName}>
                    <div className="d-flex rounded remotive-primary-10-background m-1">
                        <div className="col-8 px-2 pt-1 pb-1">
                            <div className="border-end h-100">
                                <p className="m-0 remotive-font-md text-truncate">{sourceRecordingFile.displayName}</p>
                            </div>
                        </div>
                        <div className="col-2 col-md-3 px-2 pt-1 pb-1 flex-truncate">
                            <div className="border-end h-100 flex-truncate">
                                <p className="m-0 remotive-font-md text-truncate">File size</p>
                                <p className="m-0 me-1 text-secondary remotive-font-sm text-truncate">{`${
                                    Math.round((sourceRecordingFile.sizeInBytes / 1000 + Number.EPSILON) * 10) / 10
                                } KB`}</p>
                            </div>
                        </div>
                        <div className="col-2 col-md-1 d-flex justify-content-center align-items-center p-1 remotive-primary-60-color">
                            <button
                                className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_VIEWER_RECORDING,
                                        props.currentBillableUnit,
                                        props.currentProject
                                    )
                                }
                                onClick={() => {
                                    downloadSourceRecordingFile(sourceRecordingFile)
                                }}
                            >
                                <DownloadIcon sx={{ fontSize: 20 }} />
                            </button>

                            <button
                                className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_EDITOR_RECORDING,
                                        props.currentBillableUnit,
                                        props.currentProject
                                    )
                                }
                                onClick={() =>
                                    props.openConfirmationDialog({
                                        dialogTitle: 'Are you sure?',
                                        bodyText: (
                                            <>
                                                Are you sure you want to delete the source recording file{' '}
                                                <b>{sourceRecordingFile.displayName}?</b>
                                            </>
                                        ),
                                        bodySubtitle: 'You can not undo this action.',
                                        confirmButtonText: 'Yes, delete it',
                                        cancelButtonText: 'No, cancel',
                                        handleCancelFunction: () => console.log,
                                        handleConfirmFunction: () => deleteSourceRecordingFile(sourceRecordingFile),
                                    } as ConfirmDialogProperties)
                                }
                            >
                                <DeleteIcon sx={{ fontSize: 20 }} />
                            </button>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const brokerConfigFileList = () => {
        return props.currentRecording?.brokerConfigurations.map((folder, index) => {
            return (
                <div key={folder.name}>
                    <div className={`d-flex rounded remotive-primary-10-background m-1`}>
                        <div className="col-10 col-md-11 px-2 pt-1 pb-1">
                            <div className="h-100 border-end">
                                <p className="m-0 remotive-font-md text-truncate">{folder.name}</p>
                                {folder.name !== 'default' && (
                                    <p className="m-0 text-secondary remotive-font-sm text-truncate">{`${folder.files.length} file(s)`}</p>
                                )}
                                {folder.name === 'default' && (
                                    <p className="m-0 text-secondary remotive-font-sm text-truncate">
                                        This will be autogenerated from recording
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-2 col-md-1 d-flex justify-content-center align-items-center remotive-primary-60-color">
                            <button
                                className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_VIEWER_RECORDING,
                                        props.currentBillableUnit,
                                        props.currentProject
                                    ) ||
                                    (folder.name === 'default' && props.isRecordingValid === false)
                                }
                                onClick={() => {
                                    productAnalyticsClient.track(AnalyticsActions.DOWNLOAD_CONFIGURATION)
                                    downloadBrokerConfigAsZip(folder)
                                }}
                            >
                                <DownloadIcon sx={{ fontSize: 20 }} />
                            </button>
                            <button
                                className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_EDITOR_RECORDING,
                                        props.currentBillableUnit,
                                        props.currentProject
                                    ) || folder.name === 'default'
                                }
                                onClick={() =>
                                    props.openConfirmationDialog({
                                        dialogTitle: 'Are you sure?',
                                        bodyText: (
                                            <>
                                                Are you sure you want to delete the signal transformation{' '}
                                                <b>{folder.name}?</b>
                                            </>
                                        ),
                                        bodySubtitle: 'You can not undo this action.',
                                        confirmButtonText: 'Yes, delete it',
                                        cancelButtonText: 'No, cancel',
                                        handleCancelFunction: () => console.log,
                                        handleConfirmFunction: () => {
                                            productAnalyticsClient.track(AnalyticsActions.DELETE_CONFIGURATION)
                                            deleteBrokerConfigFiles(folder)
                                        },
                                    } as ConfirmDialogProperties)
                                }
                            >
                                <DeleteIcon sx={{ fontSize: 20 }} />
                            </button>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const editRecording = (recordingFile: RecordingFile) => {
        setRecordingFileToEdit(recordingFile)
        setShowEditRecordingConfigurationModal(true)
    }

    const recordingFileList = () => {
        if (
            props.currentProject !== undefined &&
            props.currentBillableUnit !== undefined &&
            props.currentRecording !== undefined
        ) {
            return props.currentRecording.recordings.map((recordingFile) => {
                return (
                    <div key={recordingFile.fileName}>
                        <RecordingFileListItem
                            currentUser={props.currentUser}
                            refreshRecordingSession={() => props.refreshRecordingSession()}
                            parentRecordingSession={props.currentRecording!}
                            recordingFile={recordingFile}
                            editRecordingFunction={(recordingFile: RecordingFile) => editRecording(recordingFile)}
                            project={props.currentProject!}
                            billableUnit={props.currentBillableUnit!}
                            openConfirmationDialog={props.openConfirmationDialog}
                        />
                    </div>
                )
            })
        }
        return <></>
    }

    const recordingFilesSection = () => {
        return (
            <>
                <div className="d-flex flex-row flex-truncate">
                    <p className="fs-6 mb-0 me-3">
                        <b>Recording files</b>
                    </p>
                    {props.recordingRequiresConfiguration && (
                        <div className="d-flex flex-row align-items-center">
                            <WarningIcon sx={{ fontSize: 16 }} className="text-warning me-1" />
                            <p className="remotive-font-sm m-0 flex-wrap">{props.recordingConfigurationReason}</p>
                        </div>
                    )}
                </div>
                <p className="mb-0 remotive-font-sm text-secondary">{`These are all the recording files from this recording session. Together with a signal database you can play these files on a RemotiveBroker.`}</p>
                {componentState === ComponentState.UPLOADING ? (
                    <>
                        <div className="py-5">
                            <FileUploadingSection
                                uploadPercent={uploadPercent}
                                uploadingText="Uploading recording file..."
                                uploadCompleteText="Upload complete, processing will start shortly"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {props.currentBillableUnit && props.currentProject && (
                            <div className="mx-1" key={processingAccordionKey}>
                                <ProcessingRecordingsAccordion
                                    componentKey={processingAccordionKey}
                                    project={props.currentProject}
                                    billableUnit={props.currentBillableUnit}
                                    onProcessingFinishedCallback={() => props.refreshRecordingSession()}
                                    parentRecordingSession={props.currentRecording}
                                />
                            </div>
                        )}
                        {recordingFileList()}
                    </>
                )}
            </>
        )
    }

    const mediaFilesSection = () => {
        return (
            <>
                <p className="fs-6 mb-0">
                    <b>Media files</b>
                </p>
                <p className="mb-0 remotive-font-sm text-secondary">
                    We support images (<b>png</b>, <b>jpg</b>, <b>jpeg</b>, <b>webp</b>), videos (<b>mp4</b>, <b>mov</b>
                    ), Jupyter notebooks (<b>.ipynb</b>) and zip files (<b>.zip</b>). We recommend using the <b>mp4</b>{' '}
                    file format for videos due to better browser support.
                </p>
                {mediaFileList()}
                {mediaFileDropzone()}
            </>
        )
    }

    const sourceFilesSection = () => {
        return (
            <>
                <p className="fs-6 mb-0">
                    <b>Source recording files</b>
                </p>
                <p className="mb-0 remotive-font-sm text-secondary">
                    These files are the source recording files that have been uploaded to this recording session. Only
                    non-RemotiveBroker recording files will show up in this list.
                </p>
                {sourceRecordingFileList()}
            </>
        )
    }

    const recordingFileDropzone = () => {
        if (props.currentProject) {
            return (
                <RecordingDropzone
                    analyticsProperties={
                        {
                            analyticsContext: AnalyticsContext.FILES_TAB,
                            currentBillableUnit: props.currentBillableUnit,
                            currentUser: props.currentUser,
                        } as AnalyticsProperties
                    }
                    hasPermissionToUpload={hasPermission(
                        Permission.PROJECT_EDITOR_RECORDING,
                        props.currentBillableUnit,
                        props.currentProject
                    )}
                    project={props.currentProject!}
                    ref={null}
                    type="recordingFile"
                    parentRecordingSession={props.currentRecording}
                    onUploadComplete={() => {
                        setProcessingAccordionKey(randomString())
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    }}
                />
            )
        }
        return <></>
    }

    const configFilesSection = () => {
        return (
            <>
                <p className="fs-6 mb-0">
                    <b>Signal transformations</b>
                </p>
                <p className="mb-0 remotive-font-sm text-secondary">
                    By default there is no transformation applied to raw signals but you can upload transformations that
                    are applied when playing a recording. Transformations are done using custom broker configurations.
                </p>
                {brokerConfigFileList()}
                {configurationFileDropzone()}

                <div>
                    {brokerConfigFilesPreparedForUpload && (
                        <div>
                            <p>Would you like to upload the following transformation</p>
                            {brokerConfigFilesPreparedForUpload.map((f) => (
                                <p className={'small p-0 m-0'}>{f.path}</p>
                            ))}
                            {!configurationAlreadyExists() && (
                                <button
                                    className={
                                        'btn remotive-btn-sm rounded-2 remotive-btn-primary m-1 p-1 border-0 mx-0'
                                    }
                                    onClick={async () => {
                                        productAnalyticsClient.track(AnalyticsActions.UPLOAD_CONFIGURATION)
                                        await saveBrokerConfigFiles()
                                    }}
                                >
                                    Upload
                                </button>
                            )}
                            {configurationAlreadyExists() && (
                                <>
                                    <p className={'small'}>
                                        <b>This will overwrite your existing configuration</b>
                                    </p>
                                    <button
                                        className={
                                            'btn remotive-btn-sm rounded-2 remotive-btn-primary m-1 p-1 border-0 mx-0'
                                        }
                                        onClick={async () => {
                                            productAnalyticsClient.track(AnalyticsActions.UPLOAD_CONFIGURATION)
                                            await deleteBrokerConfigFiles(getConfigurationIfAlreadyExists()!, true)
                                            await saveBrokerConfigFiles()
                                        }}
                                    >
                                        Upload and overwrite
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </>
        )
    }

    const mediaFileDropzone = () => {
        if (hasPermission(Permission.PROJECT_EDITOR_RECORDING, props.currentBillableUnit, props.currentProject)) {
            return (
                <div className="d-flex m-1" style={{ height: '47px !important' }}>
                    <Dropzone
                        multiple={false}
                        accept={ACCEPTED_MEDIA_FILE_TYPES as Accept}
                        onDrop={(acceptedFiles) => {
                            productAnalyticsClient.track(AnalyticsActions.UPLOAD_MEDIA_FILE)
                            uploadMediaFile(acceptedFiles[0])
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                className="col-12 dropzone p-1 remotive-primary-0-background rounded-2 w-100 "
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <p className="m-0 remotive-font-md remotive-primary-70-color text-center">
                                    <CloudUploadIcon className="me-2" sx={{ fontSize: 35 }} /> Drag a file here or click
                                    to <b>upload media</b>
                                </p>
                            </div>
                        )}
                    </Dropzone>
                </div>
            )
        }
        return <></>
    }

    const getConfigurationIfAlreadyExists: () => BrokerConfigurationFolder | undefined = () => {
        // Path differs depending on if we use dropzone (starts with /) or pick a file with file picker (without /).
        // here we make sure to always compare without slash
        const brokerPath = brokerConfigFilesPreparedForUpload![0]!.path!
        let pathName = ''
        if (brokerPath.startsWith('/')) {
            pathName = brokerPath.substring(brokerPath.indexOf('/') + 1)
        } else {
            pathName = brokerPath
        }
        const configs = props.currentRecording!.brokerConfigurations.filter((c) => {
            return c.name === pathName.substring(0, pathName.indexOf('/'))
        })
        return configs.length > 0 ? configs[0] : undefined
    }

    const configurationAlreadyExists = () => getConfigurationIfAlreadyExists() !== undefined

    const configurationFileDropzone = () => {
        if (hasPermission(Permission.PROJECT_EDITOR_RECORDING, props.currentBillableUnit, props.currentProject)) {
            return (
                <div className="d-flex m-1" style={{ height: '47px !important' }}>
                    <Dropzone
                        useFsAccessApi={false}
                        multiple={true}
                        onDrop={(acceptedFiles, fileRejections, event: any) => {
                            acceptedFiles.forEach((f) => console.log((f as FileWithPath).path))
                            setBrokerConfigFilesPreparedForUpload(acceptedFiles)
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                className="col-12 dropzone remotive-primary-0-background p-1 rounded-2 w-100 "
                                {...getRootProps()}
                            >
                                <input
                                    {...getInputProps()}
                                    {...{
                                        type: 'file',
                                        directory: '',
                                        multiple: true,
                                        webkitdirectory: '',
                                        allowdirs: '',
                                    }}
                                />
                                <p className="m-0 remotive-font-md remotive-primary-70-color text-center">
                                    <CloudUploadIcon className="me-2" sx={{ fontSize: 35 }} /> Drag a folder here or
                                    click to <b>upload transformations folder</b>
                                </p>
                            </div>
                        )}
                    </Dropzone>
                </div>
            )
        }
        return <></>
    }

    const cliHints = () => {
        const hints: Array<CliHint> = []
        //remotive cloud recordings download-configuration
        const downloadRecordingFileHints: Array<CliHint> =
            props.currentRecording?.recordings.map((recordingFile) => {
                return {
                    title: `Download recording file ${recordingFile.fileName}`,
                    command: `remotive cloud recordings download-recording-file '${recordingFile.fileName}'  --recording-session ${props.currentRecording?.sessionId} --project ${props.currentProject?.uid}`,
                }
            }) || []

        const downloadConfigHints: Array<CliHint> =
            props.currentRecording?.brokerConfigurations.map((folder) => {
                return {
                    title: `Download transformation ${folder.name} as zip`,
                    command: `remotive cloud recordings download-broker-configuration '${folder.name}'  --recording-session ${props.currentRecording?.sessionId} --project ${props.currentProject?.uid}`,
                }
            }) || []

        hints.push(...downloadRecordingFileHints)
        hints.push(...downloadConfigHints)
        return hints
    }

    const fileUploadingSection = () => {
        return (
            <>
                <div className="text-center h-100 mt-5 mb-5">
                    {uploadPercent < 100 && (
                        <div className="mb-3">
                            <ProgressBar
                                className={`col-12 col-lg-8 offset-lg-2 remotive-primary-0-color my-5`}
                                label={`${uploadPercent}%`}
                                now={uploadPercent}
                            />
                            <p className={`fs-5 m-1 mb-3`}>Uploading recording file...</p>
                        </div>
                    )}
                    {uploadPercent >= 100 && (
                        <div className="mb-3">
                            <SuccessIcon sx={{ fontSize: 40 }} className="my-3 remotive-success-60-color" />
                            <p className={`fs-5 m-1 mb-3`}>Upload complete, processing will start shortly</p>
                        </div>
                    )}
                    <Spinner className={`remotive-primary-50-color`} />
                </div>
            </>
        )
    }

    const filesContainer = () => {
        if (props.currentRecording !== undefined) {
            return (
                <>
                    <div className="mt-3 mb-3">
                        <div className="px-2">{recordingFilesSection()}</div>
                        <div className="mt-5 mb-5" />
                        {props.currentRecording.sourceRecordingFiles.length > 0 && (
                            <>
                                <div className="px-2">{sourceFilesSection()}</div>
                                <div className="mt-5 mb-5" />
                            </>
                        )}
                        <div className="px-2">{configFilesSection()}</div>
                        <div className="m-5" />
                        <div className="px-2">{mediaFilesSection()}</div>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <Card className="shadow-sm rounded-4 border-0 text-start mb-4" style={{ minHeight: '300px' }}>
                <Card.Body className="px-1 py-0">{filesContainer()}</Card.Body>
            </Card>
            <CliHintContainer
                analyticsProperties={{
                    currentBillableUnit: props.currentBillableUnit,
                    currentUser: props.currentUser,
                    analyticsContext: AnalyticsContext.VISUALIZE_TAB,
                }}
                hints={cliHints()}
            />
            <ViewFileModal
                onVideoLoadedCallback={() => props.setPageState(PageState.DONE)}
                show={showViewFileModal}
                handleCloseFunction={() => setShowViewFileModal(false)}
                fileToView={fileToView.current}
            />
            <EditRecordingConfigurationModal
                refreshRecordingSession={props.refreshRecordingSession}
                availableSignalDatabases={props.availableSignalDatabases}
                currentProject={props.currentProject}
                currentRecordingSession={props.currentRecording}
                currentRecordingFile={recordingFileToEdit}
                show={showEditRecordingConfigurationModal}
                handleCloseFunction={() => setShowEditRecordingConfigurationModal(false)}
            />
        </>
    )
}
