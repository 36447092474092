import { Project } from '../api/CloudApi/types'

export const isLocalOrDevEnvironment = () => {
    return (
        window.location.hostname.startsWith('localhost') ||
        window.location.hostname.startsWith('console.cloud-dev') ||
        window.location.hostname.startsWith('cloud-dev')
    )
}

export const annotationFeatureEnabled = (project: Project | undefined) => {
    if (project === undefined) {
        return false
    }
    console.log(`local: ${isLocalOrDevEnvironment()}, project: ${project.uid}`)
    return isLocalOrDevEnvironment() || project.uid === 'vcc-hallered' || project.uid === 'durability-lab'
}
