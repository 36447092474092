import { ReactElement, useEffect, useState } from 'react'
import { Container, Dropdown } from 'react-bootstrap'
import {
    AuthenticatedUser,
    FileObject,
    FileObjectsContainer,
    FileObjectTypeEnum,
    Project,
    UserBillableUnitInfo,
} from '../../api/CloudApi/types'
import { BreadCrumb, BreadCrumbs } from '../../types/BreadCrumbs'
import { useSearchParams } from 'react-router-dom'
import NavigationBar from '../../components/navigation/NavigationBar'
import { CURRENT_PATH_PARAM } from '../../utils/queryParams'
import RLCard from '../../components/cards/RLCard'
import { PageDetails } from '../../utils/pageDetails'
import {
    DeleteIcon,
    DirectoryIcon,
    DownloadIcon,
    GenericFileIcon,
    GridViewIcon,
    ImageFileIcon,
    ListViewIcon,
    MoreVerticalIcon,
    RefreshIcon,
    SoundFileIcon,
    TextFileIcon,
    VideoFileIcon,
    ViewIcon,
    ZippedFileIcon,
} from '../../assets/Icons'
import { hasPermission, Permission } from '../../utils/permission'
import { OrganisationRoutes } from '../../Routes'
import CloudApi from '../../api/CloudApi'
import { ComponentState } from '../../types/ComponentState'
import LoadingContainer from '../../components/LoadingContainer'
import {
    COMMON_COMPRESSED_FILE_TYPES,
    COMMON_IMAGE_FILE_TYPES,
    COMMON_SOUND_FILE_TYPES,
    COMMON_TEXT_FILE_TYPES,
    COMMON_VIDEO_FILE_TYPES,
} from './FileFormats'
import { WarningRounded } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../utils/toast'
import ErrorContainer from '../../components/ErrorContainer'
import NotFoundContaner from '../../components/NotFoundContainer'
import CliHintContainer, { CliHint } from '../../components/CliHintContainer'
import { ConfirmDialogProperties } from '../../types/ConfirmDialogProperties'
import FilePreviewModal from './FilePreviewModal'

interface FilesProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentUser: AuthenticatedUser | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

function formatSize(size: number): string {
    const KB = 1024
    const MB = KB * 1024
    const GB = MB * 1024
    const TB = GB * 1024

    if (size < KB) {
        return size.toFixed(2) + ' B'
    } else if (size < MB) {
        return (size / KB).toFixed(2) + ' KB'
    } else if (size < GB) {
        return (size / MB).toFixed(2) + ' MB'
    } else if (size < TB) {
        return (size / GB).toFixed(2) + ' GB'
    } else {
        return (size / TB).toFixed(2) + ' TB'
    }
}

enum LayoutType {
    LIST = 'LIST',
    CARDS = 'CARDS',
}

const PAGE_TITLE = 'Storage'

export default function Files(props: FilesProps) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [componentState, setComponentState] = useState(ComponentState.LOADING)
    const [currentLayoutType, setLayoutType] = useState<LayoutType>(LayoutType.CARDS)
    const [fileObjectsContainer, setFileObjectsContainer] = useState<FileObjectsContainer | undefined>(undefined)
    const ICON_SIZE = currentLayoutType === LayoutType.LIST ? 16 : 22
    const [showModal, setShowModal] = useState(false)
    const [modalText, setModalText] = useState('')
    const [modalTitle, setModalTitle] = useState('')

    useEffect(() => {
        const initializePage = async (project: Project) => {
            const pathFromQuery = await searchParams.get(CURRENT_PATH_PARAM)
            if (pathFromQuery !== null) {
                console.debug(
                    `Found a path in query params, will use that as starting directory. Path is=${pathFromQuery}`
                )
                listFiles(project, pathFromQuery)
            } else {
                console.debug(`No path in query, will fetch root directory`)
                listFiles(project, '/')
            }
        }

        if (fileObjectsContainer === undefined && props.currentProject !== undefined && searchParams !== undefined) {
            initializePage(props.currentProject!)
        }
    }, [props.currentProject, searchParams, fileObjectsContainer])

    useEffect(() => {
        if (fileObjectsContainer !== undefined) {
            searchParams.set(CURRENT_PATH_PARAM, fileObjectsContainer.path)
            setSearchParams(searchParams)
        }
    }, [fileObjectsContainer])

    const listFiles = (project: Project, path?: string) => {
        const list = async () => {
            try {
                setComponentState(ComponentState.LOADING)
                console.log(`Path: ${path}`)
                const response = await CloudApi.listStorageFilesInPath(project, path)
                setFileObjectsContainer(response.data)
                setComponentState(ComponentState.DONE)
            } catch (e: any) {
                if (e.response.status === 404) {
                    // When the last file in a directory is removed, the directory does no longer exist.
                    // For now lets go to root when this happens.
                    if (path !== '/') {
                        listFiles(project, '/')
                    } else {
                        console.log('404 at / - This probably means that there are no files uploaded')
                        setComponentState(ComponentState.DONE)
                    }
                } else {
                    console.error(e)
                    toast.error(
                        formattedToastMessage(
                            'File error',
                            'Failed to list files in current directory, please try again later.'
                        )
                    )
                    setComponentState(ComponentState.DONE)
                }
            }
        }

        list()
    }

    const uploadGenericFile = () => {}

    const deleteFile = (fileObject: FileObject, project: Project) => {
        const deleteIt = async () => {
            setComponentState(ComponentState.LOADING)
            try {
                const deleteResponse = await CloudApi.deleteStorageFile(project, fileObject.path)
                if (deleteResponse.status < 300) {
                    toast.success(`Successfully deleted ${fileObject.name}!`)
                    listFiles(project, fileObjectsContainer?.path)
                }
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to delete selected file, please try again later.')
                )
            }
            setComponentState(ComponentState.DONE)
        }

        deleteIt()
    }

    const getFileContents = (fileObject: FileObject, project: Project) => {
        const download = async () => {
            try {
                setComponentState(ComponentState.LOADING)
                const downloadUrl = await CloudApi.getStorageFileUrl(project, `${fileObject.path}`)

                const response = await CloudApi.downloadPreviewContentsFromSignedUrl(downloadUrl.data)

                setModalText(response.data)
                setShowModal(true)
                setModalTitle(fileObject.name)
                setComponentState(ComponentState.DONE)
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to download selected file, please try again later.')
                )
            }
            setComponentState(ComponentState.DONE)
        }

        download()
    }

    const downloadFile = (fileObject: FileObject, project: Project) => {
        const download = async () => {
            try {
                setComponentState(ComponentState.LOADING)
                const downloadUrl = await CloudApi.getStorageFileUrl(project, `${fileObject.path}`)
                const fileContents = await fetch(downloadUrl.data)
                const blob = await fileContents.blob()
                // Download file by creating a link, faking a click on it, and then removing the link again
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = fileObject.name
                link.click()
                link.parentNode?.removeChild(link)
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to download selected file, please try again later.')
                )
            }
            setComponentState(ComponentState.DONE)
        }

        download()
    }

    const clickFile = (fileObject: FileObject, project: Project) => {
        const openFile = async () => {
            try {
                setComponentState(ComponentState.LOADING)
                const downloadUrlResponse = await CloudApi.getStorageFileUrl(project, `${fileObject.path}`)
                window.open(downloadUrlResponse.data, '_blank')
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to open selected file, please try again later.')
                )
            }
            setComponentState(ComponentState.DONE)
        }

        openFile()
    }

    const clickDirectory = (fileObject: FileObject, project: Project) => {
        listFiles(project, `${fileObject.path}`)
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center text-truncate">
                    <div className="text-truncate d-flex align-items-center">
                        <GenericFileIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 lexend-light text-truncate m-0">{PAGE_TITLE}</p>
                    </div>
                    {props.currentProject !== undefined && fileObjectsContainer !== undefined && (
                        <div>
                            {/*
                            <button
                                className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1"
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_EDITOR_CONFIG,
                                        props.currentBillableUnit,
                                        props.currentProject
                                    )
                                }
                                onClick={() => {
                                    uploadGenericFile()
                                }}
                            >
                                <UploadIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                            </button>
                            */}
                            <button
                                className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary"
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_VIEWER_CONFIG,
                                        props.currentBillableUnit,
                                        props.currentProject
                                    )
                                }
                                onClick={() => {
                                    listFiles(props.currentProject!, fileObjectsContainer.path)
                                }}
                            >
                                <RefreshIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                            </button>
                        </div>
                    )}
                </div>
            </>
        )
    }

    const noFilesInPathWarning = () => {
        return (
            <>
                <NotFoundContaner infoText="We couldn't find any files..." secondaryText="Upload some files first" />
            </>
        )
    }

    const renderFileObjectPath = (fileObjectsContainer: FileObjectsContainer) => {
        if (!props.currentProject || fileObjectsContainer.path === undefined) return null
        const path = ['/', ...fileObjectsContainer.path.split('/').slice(1)]
        return (
            <div className="d-flex justify-content-start mt-2 mx-3">
                <div className="remotive-font-md m-0 d-flex">
                    {path.slice(0, path.length - 1).map((it, index, sourceArray) => (
                        <div key={it} className="me-1">
                            <p className="m-0">
                                <span
                                    className={`clickable-underlined-on-hover ${
                                        index === sourceArray.length - 1 ? 'lexend-bold' : 'text-secondary'
                                    }`}
                                    onClick={() =>
                                        listFiles(
                                            props.currentProject!,
                                            sourceArray
                                                .slice(0, index + 1)
                                                .join('/')
                                                .substring(1) + '/' // Must end with / to list directory
                                        )
                                    }
                                >
                                    {it}
                                </span>
                                {' >'}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const renderFileObjectList = (fileObjectsContainer: FileObjectsContainer) => {
        if (fileObjectsContainer.objects.length <= 0) {
            return
        }
        return fileObjectsContainer.objects
            .sort((objectA, objectB) => objectA.type.localeCompare(objectB.type))
            .map((it) => (
                <div key={`${it.path}`}>
                    <div className="flex-truncate d-flex">
                        {it.type === FileObjectTypeEnum.DIRECTORY
                            ? directoryFileCard(it)
                            : it.type === FileObjectTypeEnum.FILE
                            ? fileCard(it)
                            : unknownFileCard(it)}
                    </div>
                </div>
            ))
    }

    const getIconBasedOnFileName = (fileName: string) => {
        if (COMMON_IMAGE_FILE_TYPES.some((it) => fileName.endsWith(it))) {
            return <ImageFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
        }
        if (COMMON_TEXT_FILE_TYPES.some((it) => fileName.endsWith(it))) {
            return <TextFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
        }
        if (COMMON_VIDEO_FILE_TYPES.some((it) => fileName.endsWith(it))) {
            return <VideoFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
        }
        if (COMMON_SOUND_FILE_TYPES.some((it) => fileName.endsWith(it))) {
            return <SoundFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
        }
        if (COMMON_COMPRESSED_FILE_TYPES.some((it) => fileName.endsWith(it))) {
            return <ZippedFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
        }
        return <GenericFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
    }

    const fileCard = (fileObject: FileObject) => {
        if (props.currentProject !== undefined) {
            return fileObjectCard(
                getIconBasedOnFileName(fileObject.name),
                fileObject.name,
                formatSize(fileObject.size ?? 0),
                fileObject.uploaded,
                <></>,
                'remotive-primary-20-background',
                'remotive-success-100-color',
                false,
                () => clickFile(fileObject, props.currentProject!),
                () => getFileContents(fileObject, props.currentProject!),
                () => downloadFile(fileObject, props.currentProject!),
                () =>
                    props.openConfirmationDialog({
                        dialogTitle: 'Are you sure?',
                        bodyText: (
                            <>
                                Are you sure you want to delete the file <b>{fileObject.name}?</b>
                            </>
                        ),
                        bodySubtitle: 'You can not undo this action.',
                        confirmButtonText: 'Yes, delete it',
                        cancelButtonText: 'No, cancel',
                        handleCancelFunction: () => console.log,
                        handleConfirmFunction: () => deleteFile(fileObject, props.currentProject!),
                    } as ConfirmDialogProperties)
            )
        }
        return <></>
    }

    const unknownFileCard = (fileObject: FileObject) => {
        return fileObjectCard(
            <WarningRounded sx={{ fontSize: ICON_SIZE }} className="text-danger" />,
            fileObject.name,
            'Unknown file',
            '',
            <></>,
            'bg-danger-subtle',
            'text-danger',
            true,
            () =>
                toast.error(
                    formattedToastMessage(
                        'File error',
                        'This file is unknown, please reach out to support@remotivelabs.com.'
                    )
                ),
            () =>
                toast.error(
                    formattedToastMessage(
                        'File error',
                        'This file is unknown, please reach out to support@remotivelabs.com.'
                    )
                ),
            () =>
                toast.error(
                    formattedToastMessage(
                        'File error',
                        'This file is unknown, please reach out to support@remotivelabs.com.'
                    )
                ),
            () =>
                toast.error(
                    formattedToastMessage(
                        'File error',
                        'This file is unknown, please reach out to support@remotivelabs.com.'
                    )
                )
        )
    }

    const directoryFileCard = (fileObject: FileObject) => {
        if (props.currentProject !== undefined) {
            return fileObjectCard(
                <DirectoryIcon sx={{ fontSize: ICON_SIZE }} className="remotive-success-100-color" />,
                fileObject.name,
                'Directory',
                '',
                <></>,
                'remotive-success-10-background',
                'remotive-success-100-color',
                true,
                () => clickDirectory(fileObject, props.currentProject!),
                () => toast.error(formattedToastMessage('File error', 'It is not possible to download a directory.')),
                () => toast.error(formattedToastMessage('File error', 'It is not possible to preview a directory.')),
                () => toast.error(formattedToastMessage('File error', 'It is not possible to delete a directory.'))
            )
        }
        return <></>
    }

    const fileObjectCard = (
        icon: ReactElement,
        title: string,
        subtitle: string,
        uploaded: string | undefined,
        options: ReactElement,
        cardClassName: string,
        moreButtonClassName: string,
        isDirectory: boolean,
        onClickFunction: () => void,
        onPreviewFunction: () => void,
        onDownloadFunction: () => void,
        onDeleteFunction: () => void
    ) => {
        return (
            <div
                className={`rounded-3 ${
                    currentLayoutType === LayoutType.LIST ? 'flex-grow-1' : 'p-1 m-1'
                } ${cardClassName}`}
                style={{ width: 250, marginBottom: 3 }}
            >
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div
                        className={`d-flex flex-row align-items-center flex-truncate ${
                            currentLayoutType === LayoutType.LIST ? 'flex-grow-1 py-1' : ''
                        }`}
                    >
                        <div className="ms-2 me-3  d-flex align-items-center">{icon}</div>
                        <div
                            title={title}
                            className={`d-flex ${
                                currentLayoutType === LayoutType.LIST
                                    ? 'flex-row flex-grow-1 align-items-end'
                                    : 'flex-column'
                            } flex-truncate clickable-underlined-on-hover `}
                            onClick={onClickFunction}
                        >
                            <p
                                className={`text-truncate text-start m-0 me-2 ${
                                    currentLayoutType === LayoutType.LIST ? 'remotive-font-sm' : 'remotive-font-md'
                                }`}
                            >
                                {title}
                            </p>
                            <p
                                style={{
                                    marginBottom: currentLayoutType === LayoutType.LIST ? 1 : 0,
                                    marginRight: currentLayoutType === LayoutType.LIST ? 5 : 0,
                                }}
                                className="text-truncate text-start text-secondary remotive-font-xs"
                            >
                                {subtitle}
                            </p>
                            <p
                                style={{ marginBottom: currentLayoutType === LayoutType.LIST ? 1 : 0 }}
                                className="text-truncate text-start text-secondary remotive-font-xs"
                            >
                                {uploaded !== undefined && uploaded.length > 0 && new Date(uploaded).toLocaleString()}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="pe-2 py-0 d-flex align-items-center">
                            {!isDirectory && (
                                <Dropdown>
                                    <Dropdown.Toggle className={`p-0 bg-transparent border-0 ${moreButtonClassName}`}>
                                        <MoreVerticalIcon sx={{ fontSize: ICON_SIZE }} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        style={{ minWidth: 120 }}
                                        className="remotive-font-md remotive-dropdown-dark text-light"
                                    >
                                        <Dropdown.Item className="px-2" onClick={onDownloadFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Download</p>
                                                <DownloadIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-2" onClick={onPreviewFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Preview</p>
                                                <ViewIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-2" onClick={onDeleteFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Delete</p>
                                                <DeleteIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getBody = () => {
        switch (componentState) {
            case ComponentState.LOADING:
                return (
                    <>
                        <div className="d-flex flex-row justify-content-between">
                            <div></div>
                            <div className="me-2 ">
                                <button
                                    onClick={() => setLayoutType(LayoutType.LIST)}
                                    className={`btn remotive-btn-tab ${
                                        currentLayoutType === LayoutType.LIST ? 'text-dark' : ''
                                    } p-0`}
                                >
                                    <ListViewIcon sx={{ fontSize: 20 }} />
                                </button>
                                <button
                                    onClick={() => setLayoutType(LayoutType.CARDS)}
                                    className={`btn remotive-btn-tab ${
                                        currentLayoutType === LayoutType.CARDS ? 'text-dark' : ''
                                    } p-1`}
                                >
                                    <GridViewIcon sx={{ fontSize: 20 }} />
                                </button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mx-2 mt-1">
                            <LoadingContainer spinnerSize="lg" loadingText=" " />
                        </div>
                    </>
                )

            case ComponentState.ERROR:
                return (
                    <>
                        <ErrorContainer />
                    </>
                )

            case ComponentState.DONE:
            case ComponentState.DEFAULT:
                if (fileObjectsContainer !== undefined) {
                    return (
                        <>
                            <div className="d-flex flex-row justify-content-between">
                                <div>{renderFileObjectPath(fileObjectsContainer)}</div>
                                <div className="me-2 ">
                                    <button
                                        onClick={() => setLayoutType(LayoutType.LIST)}
                                        className={`btn remotive-btn-tab ${
                                            currentLayoutType === LayoutType.LIST ? 'text-dark' : ''
                                        } p-0`}
                                    >
                                        <ListViewIcon sx={{ fontSize: 20 }} />
                                    </button>
                                    <button
                                        onClick={() => setLayoutType(LayoutType.CARDS)}
                                        className={`btn remotive-btn-tab ${
                                            currentLayoutType === LayoutType.CARDS ? 'text-dark' : ''
                                        } p-1`}
                                    >
                                        <GridViewIcon sx={{ fontSize: 20 }} />
                                    </button>
                                </div>
                            </div>
                            <div
                                className={`${
                                    currentLayoutType === LayoutType.LIST ? 'col-12 px-2' : 'd-flex flex-wrap mx-2'
                                }  my-1`}
                            >
                                {renderFileObjectList(fileObjectsContainer)}
                            </div>
                        </>
                    )
                }

                return noFilesInPathWarning()
        }
    }

    const breadCrumbs = () => {
        return {
            trail: [
                {
                    title: 'Home /',
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                } as BreadCrumb,
            ],
            current: {
                title: PAGE_TITLE,
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    const cliHints = () => {
        const hints: Array<CliHint> = []
        hints.push({
            title: `Copy file to storage root directory`,
            command: `remotive cloud storage cp myfile.txt rcs://some_dir  --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `Copy file to remote storage but change filename`,
            command: `remotive cloud storage cp myfile.txt rcs://myfile2.txt  --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `Copy file from remote storage`,
            command: `remotive cloud storage cp rcs://some_dir/myfile.txt .  --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `Copy file from remote storage but change filename`,
            command: `remotive cloud storage cp rcs://some_dir/myfile.txt myfile2.txt  --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `Delete a remote file`,
            command: `remotive cloud storage rm rcs://some_dir/myfile.txt --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `List files in a directory`,
            command: `remotive cloud storage ls rcs://some_dir/ --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `List files and directories matching the prefix (skip / at the end)`,
            command: `remotive cloud storage ls rcs://some_prefix --project ${props.currentProject?.uid}`,
        })

        return hints
    }

    const closeModal = () => {
        setShowModal(false)
        setModalText('')
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                isDemoGuideActive={false}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                <div className="mt-3">{pageHeader()}</div>
                <div className="mt-2">
                    <RLCard body={getBody()} />
                </div>
                <CliHintContainer hints={cliHints()} />
            </Container>
            <FilePreviewModal
                show={showModal}
                text={modalText}
                title={modalTitle}
                handleCloseFunction={() => closeModal()}
            />
        </div>
    )
}
